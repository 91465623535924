jQuery(document).ready(function ($) {
    "use strict";

    // Enable smooth anchor link scrolling
    // on all link elements (href="#...")
    $.localScroll({target: 'body', offset: -$('.main-header').outerHeight() });

    $('[data-link]').on('click', function (e) {
        e.preventDefault();
        var did = $(this).data('link');
        $('div' + did).toggleClass('is-active');
    });

    // Tab functionality
    $('[data-tabs]').tabs();

    $('[data-map]').gMap();

    // Opens Magnific Popup's, using the data attribute to group items into a gallery,
    // For dynamic type to work, you will need to add the class mfp-TYPE to all elements.
    // Use mfp-image for images, mfp-iframe for videos, and mfp-inline for HTML.
    /* Look at this further, can be more efficient. */
    $('[data-mfp]').on('click', function (e) {
        e.preventDefault();

        $('[data-mfp]').magnificPopup({
            gallery: {
                enabled: true
            }
        }).magnificPopup('open');
    });

    // Magnific popup for galleries
    $('.magnific-gallery').magnificPopup({
        type: 'image',
        gallery:{
            enabled:true
        }
    });

    // Check for tab links
    function urlParam( name ) {

        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec( window.location.href );

        if ( results == null ) {
           return null;
        }

        return decodeURI( results[1] ) || 0;
    }

    var service = urlParam( 'service-tab' );

    console.log( service );

    if ( service ) {


        if ( typeof $( '[data-link="' + service + '"]' ) != 'undefined' ) {

            console.log( $( '[data-link="' + service + '"]' ) );
            
            if ( $( '[data-link="' + service + '"]' ).length ) {

                $( '[data-link="' + service + '"]' ).siblings().removeClass( 'is-active' );
                $( '[data-link="' + service + '"]' ).addClass( 'is-active' );

                $( '[data-link="' + service + '"]' ).closest( '[data-tabs]' ).find( '[data-tab-content] > *' ).hide();
                $( '[data-link="' + service + '"]' ).closest( '[data-tabs]' ).find( '[data-tab-content] [data-content="' + service + '"]' ).show();
            }
        }
    }

});
