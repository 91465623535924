jQuery(document).ready(function ($) {
    "use strict";

    function adjustNavHeight() {

        if ( $( '.header' ).length ) {
            $( 'body' ).css({ 'paddingTop': $( '.header' ).outerHeight() });
        }
    }

    $( '.mobile-menu__item.menu-item-has-children' ).on( 'click', function (e) {
        $( this ).toggleClass( 'mobile-menu__item--active' );
        $( this ).siblings().removeClass( 'mobile-menu__item--active' );
    });

    adjustNavHeight();

    $( window ).on( 'resize', function() {
        adjustNavHeight();
    });
});
