(function () {
    "use strict";

    /**
     * Asynchronously load google maps and add to element
     * @param  object options   Modify default options
     * @return jQuery Object    Element (chaining)
     */
    jQuery.fn.gMap = function (options) {

        // Merge provided options with
        // some default options
        options = jQuery.extend({
            // Default options
            latLng: {lat: -25.363, lng: 131.044},
            label: 'We\'re here',
            scrollable: false,
            scrollwheel: false
        }, options);

        var // Store `this` so it's available within functions
            self = this;

        // Function to check if maps api is loaded
        function isgMapsLoaded() {
            return (typeof google === 'object' && typeof google.maps === 'object' && jQuery('#async-maps-api').length);
        }

        // Async maps api
        function loadgMapsApi() {
            // If it's already loaded, fire the callback
            if (isgMapsLoaded()) { return window.gMapsLoaded(); }

            // Load the maps api asynchronously firing gMapsLoaded on completion
            var url = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCtvEg0JR6UZOiLB_pPKXDztHaReaYHyLw&v=3&callback=gMapsLoaded';
            jQuery('<script type="text/javascript" id="async-maps-api" src="' + url + '" />').prependTo('body');
        }

        // When maps api fires loaded callback refire this function
        window.gMapsLoaded = function () {
            // Fire for each element matching query
            jQuery(self).gMap(options);
        };

        if (!jQuery(this).length) { return false; }

        // Main logic for processing maps
        if (isgMapsLoaded()) {
            // Support multiple elements
            jQuery(this).each(function () {
                // Load the map
                var map = new google.maps.Map(this, {
                        zoom: 14,
                        center: options.latLng,
                        draggable: false,
                        scrollable: false
                    }),
                    // Add the marker to the map
                    marker = new google.maps.Marker({
                        position: options.latLng,
                        map: map,
                        title: options.label
                    });

                // Maps attribute has value, use as address
                if (jQuery(this).data('map') && jQuery(this).data('map') !== '') { options.address = jQuery(this).data('map'); }

                // If address is defined, get latLng from it
                if (options.address !== undefined) {
                    // Get the coordinates using geocoder
                    new google.maps.Geocoder().geocode({'address': options.address}, function (results, status) {
                        // If GeoCoder returned a result
                        if (status === google.maps.GeocoderStatus.OK) {
                            // Set the position of the marker and map center
                            marker.setPosition(results[0].geometry.location);
                            map.setCenter(results[0].geometry.location);
                        }
                    });
                }
            });
        } else {
            // Load the API
            loadgMapsApi();
        }

        // Return the jQuery element
        return this;
    };

}());
